<template>
 <div v-show="showBind" class="mask"> 
    <transition name="bind">
      <div v-show="showBind">
        <simple-keyboard ref="keyboard" v-show="showKeyboard" @onChange="onChange" @onKeyPress="onKeyPress" :input="cardNum"/>
        <div class="my-Bind"> 
          <i class="fa fa-times icon-close" aria-hidden="true" @click="closeBind"></i>
          <div v-if="showFirst"  class="bind-box">
            <input onpaste="return false" oncontextmenu="return false" oncopy="return false" oncut="return false" @focus="onInputFocus" @input="onInputChange" v-model="cardNum" class="input" type="text" placeholder="请输入激活码">
            <img @click="bindCard" class="btn-confirm" src="@/assets/listenText/btn-confirm.png" alt="">
            <div class="tips">
              温馨提示：激活有效期为激活之日起的365天。<br/>请学完一册，再激活下一册，以免提早过期。
            </div>
            <div class="btn-record-box">
              <div class="btn-record" @click="showRecordHandler">激活记录</div>

            </div>
          </div>
          <div v-if="showRecord"  class="bind-box bind-box-record">
            <div class="btn-record btn-record-fixed" @click="showRecordHandler">激活记录</div>
            <div class="record-content">
              <div v-for="(item, index) in list" :key="index">
                {{item.cardName}}：{{item.bindingTime}}至{{item.deadline}}
              </div>
            </div>
          </div>
        </div>
      </div>
    </transition>
 </div>
</template>

<script>
// import horizontalScreen from '@/components/horizontal-screen'
import api from '@/api'
import {mapGetters} from 'vuex'
import simpleKeyboard from './simpleKeyboard.vue'
export default {
  components: {simpleKeyboard},
  computed: {
    ...mapGetters(['userId'])
  }, 
  mounted() {
    if(document.querySelector('#app section').style.transform === 'rotate(0deg)') {
      // 横屏  word-container
      document.querySelector('.my-Bind').classList.add('my-Bind-horizontal')
    }
  }, 
  watch: {
    showBind(val) {
      if (val) {
        this.cardNum = undefined
        this.showRecord = false
        this.showFirst = true
        this.fetchCardList()
      }
    }
  },
  data() {
    return {
      showKeyboard: false,
      cardNum: undefined,
      showBind: false,
      showRecord: false,
      showFirst: true,
      list: []
    }
  },
  methods: {
    onInputFocus() {
      document.activeElement.blur();
      this.$refs.keyboard.init()
      this.showKeyboard = true
    },
    onInputChange(input) {
      console.log('onInputChange', input)
      this.cardNum = input.target.value
    },
    onKeyPress(button) {
      console.log('button', button)
      if (button == '{ent}') {
        this.showKeyboard = false
      }
    },
    onChange(input) {
      this.cardNum = input
    },
    async fetchCardList() {
      const {code, data} =  await api.getCardList()
      if (code == 1) {
        this.list = data.result
      }
    },
    async bindCard () {
      if (!this.cardNum) {
        this.$myToast({msg: '请输入激活码'})
      } else {
        const {msg, code} = await api.bindingCard({
          cardNum: this.cardNum,
          userId: this.userId
        })
        if (code == 1) {
          this.$emit('on-bind')
          this.closeBind()
        }
        this.$myToast({msg})
      }
      
    },
    closeBind() {
      this.showKeyboard = false
      this.showBind = false
    },
    showRecordHandler() {
      this.showRecord = true
      this.showFirst = false
    }
  }
}
</script>

<style lang="scss" scoped>
.mask {
  // background: rgba($color: #000000, $alpha: 0.6);
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 9999;
}
.my-Bind-horizontal {
  background-image: linear-gradient(to bottom, #ffa5a4 0%, #ffffff 100%);
  // #ffa5a4 ffd5d4
  position: fixed;
  top: 50%;
  left: 50%;
  width: 50%;
  height: 88%;
  transform: translateX(-50%) translateY(-50%);
  padding: 10px;
  // color: #fff;
  z-index: 9999;
  text-align: center;
  font-size: 26px;
  // border-radius: 16px;
  transform-origin: center center;
}
.my-Bind {
  color: #000;
  background-image: linear-gradient(to bottom, #ffa5a4 0%, #ffffff 100%);
  /* border: solid 0.01333rem #e78d02; */
  padding: 10px;
  box-sizing: border-box;
  position: absolute;
  overflow: scroll;
  height: 70%;
  bottom: 0;
  height: 94vw;
  width: 55vh;
  left: 97%;
  top: 50%;
  transform: translateX(-50%) rotate(90deg);
  transform-origin: top center;
  .bind-box {
    background-image: linear-gradient(to bottom, #ffd5d4 0%, #ffffff 100%);
    border-radius: 8px;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
  }
  .bind-box-record {
    margin-top: 40px;
    height: 80%;
  }
  .icon-close {
    position: absolute;
    color: #fff;
    font-size: 40px;
    top: 10px;
    right: 10px;
  }
  .input {
    border: solid 1px #000;
    width: 60%;
    height: 54px;
    display: inline-block;
    font-size: 30px;
    padding: 0 5px;
    margin: 0 auto;
    margin-top: 15vw;
    &::placeholder {
      color: #ccc;
    }
  }
  .btn-confirm {
    margin: 0 auto;
    margin-top: 8vw;
    width: 138.5px;
    height: 54px;
    display: inline-block;
  }
  .tips {
    margin-top: 8vw;
    font-size: 14px;
    text-align: left;
    padding: 0 10px;
  }
  .btn-record-box {
    margin-top: 5vw;
    padding: 0 10px;
    text-align: right;
  }
  .btn-record {
    text-align: center;
    width: 100px;
    height: 32px;
    line-height: 32px;
    background: #ffff99;
    border-radius: 8px;
    font-size: 14px;
    display: inline-block;
  }
  .btn-record-fixed {
    position: absolute;
    top: 10px;
    left: 20px;
  }
  .record-content {
    font-size: 14px;
    text-align: left;
    padding: 0 10px;
    line-height: 24px;
    overflow: scroll;
    height: 100%;
  }
  &-horizontal {
    background-image: linear-gradient(to bottom, #ffa5a4 0%, #ffffff 100%);
    // #ffa5a4 ffd5d4
    position: fixed;
    top: 50%;
    left: 50%;
    width: 50%;
    height: 88%;
    transform: translateX(-50%) translateY(-50%);
    padding: 10px;
    // color: #fff;
    z-index: 9999;
    text-align: center;
    font-size: 26px;
    // border-radius: 16px;
    transform-origin: center center;
    .input {
      margin-top: 15vh;
    }
    .btn-confirm {
      margin-top: 8vh;
    }
    .tips {
      margin-top: 8vh;
    }
    .btn-record-box {
      margin-top: 5vh;
    }
  }
  
}
.bind-enter-active, .bind-leave-active {
  transition: .3s ease-out;
  transform-origin: center center;
}
.bind-enter {
  opacity: 0;
  // transform: scale(1.2);
}
.bind-leave-to {
  opacity: 0;
  // transform: scale(0.8);
}
</style>