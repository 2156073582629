<template>
  <!-- 横屏 -->
  <horizontalScreen class="v-book">
    <!-- <scaleWrap> -->
    <div class="home">
      <div class="box">
        <div class="word-container">
          <div :id="`word${index}`" :style="{ backgroundImage: 'url(' + word.bg + ')' }" class="word"
            v-for="(word, index) in showWordList.slice(0, 3)" :key="word.id">
            <div @click.stop.prevent="wordClickHandler($event, word, index)" class="icon"></div>
          </div>
        </div>
        <!-- <div class="word-container">
            <div :id="`word${index+3}`" :style="{backgroundImage: 'url(' + word.bg + ')'}" class="word" v-for="(word, index) in showWordList.slice(3, 6)" :key="word.id">
              <div @click.stop.prevent="wordClickHandler($event, word, index + 3)" class="icon"></div>
            </div>
          </div> -->
      </div>
      <img class="logo" src="@/assets/index/img-logo.png" />
      <img class="game-logo" src="@/assets/index/img-ztrz-new.png" />
      <audio id="myAudio" preload></audio>
      <img @click="backHandler" class="btn-back" src="@/assets/index/btn-back.png" />
      <div class="btn-book" :style="{ backgroundImage: 'url(' + albumNameBg + ')' }"></div>
    </div>
    <!-- </scaleWrap> -->
  </horizontalScreen>
</template>
<script>
import anime from 'animejs/lib/anime.es.js'
import horizontalScreen from '@/components/horizontal-screen'
import { isEmpty } from 'lodash'
import { mapGetters, mapMutations } from 'vuex'
export default {
  name: 'Book',
  components: {
    horizontalScreen
  },
  created () {
    this.SET_SCROLL_TOP(0)
  },
  computed: {
    ...mapGetters(['albumName', 'albumId']),
    albumNameBg () {
      return require(`@/assets/book/album${this.albumId}.jpg`)
    }
  },
  data () {
    return {
      clickAudio: require(`@/assets/sounds/click.mp3`),
      showWordList: [
        { name: ' 认字', id: 1, path: 'WordIndex', bg: require(`@/assets/book/1.png`) },
        { name: ' 检测', id: 3, path: 'CameIndex', bg: require(`@/assets/book/3.png`) },
        { name: ' 阅读', id: 2, path: 'ReadIndex', bg: require(`@/assets/book/2.png`) }
        // {name: ' 闪卡', id: 4, path: 'CardIndex', bg: require(`@/assets/book/4.png`)},
        // {name: ' 连听<br/>字词', id: 5, path: 'ListenWordIndex', bg: require(`@/assets/book/5.png`)},
        // {name: ' 连听<br/>课文', id: 6, path: 'ListenTextIndex', bg: require(`@/assets/book/6.png`)}
      ]
    }
  },
  methods: {
    isEmpty,
    ...mapMutations(['SET_SCROLL_TOP']),
    backHandler () {
      this.$router.push({ name: 'Index' })
    },
    playAudio () {
      const myAudio = document.getElementById('myAudio')
      myAudio.src = this.audioList[0]
      myAudio.play()
      let index = 0
      return new Promise((resolve) => {
        myAudio.onended = () => {
          console.log(index, this.audioList)
          if (index < this.audioList.length - 1) {
            myAudio.src = this.audioList[index + 1];
            myAudio.play()
            index++;
          } else {
            this.audioList = []
            resolve()
          }
        };
      })

    },
    // 字点击
    async wordClickHandler (event, word) {
      const parentNode = event.target.parentElement

      anime({
        targets: parentNode,
        scale: [
          { value: 1, duration: 100 },
          { value: 1.1, duration: 100 },
          { value: 1, duration: 100 }
        ],
        complete: () => {
          parentNode.style.transform = 'none'
        }
      });


      this.audioList = []
      this.audioList = [this.clickAudio]
      await this.playAudio()

      this.$router.push({
        name: word.path, query: {
          type: word.id
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.v-book {

  // font-family: 'Alibaba-PuHuiTi-Bold';
  // font-family: 'FZHuPo-M04S';
  audio {
    position: absolute;
    top: -1000px;
    left: -1000px;
    z-index: -1;
  }

  .home {
    height: 100%;
    width: 100%;
    background: url(~@/assets/index/bg-index.png) center center;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    overflow: hidden;
    position: relative;
  }

  .box {
    position: absolute;
    // bottom: 16px;
    left: 0;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
  }

  // .logo {
  //   width: 28px;
  //   left: 5px;
  //   top: 10PX;
  //   position: fixed;
  // }
  .game-logo {
    width: 73.5px;
    left: 48px;
    top: 5PX;
    position: fixed;
  }

  .btn-book {
    color: #72350e;
    font-size: 30px;
    z-index: 9;
    position: fixed;
    width: 172.5px;
    height: 52px;
    display: flex;
    align-items: center;
    justify-content: center;
    left: 50%;
    transform: translateX(-50%);
    bottom: 12px;
    background-size: 100% 102%;
    border-radius: 20px;
  }

  .btn-back {
    z-index: 99;
    position: fixed;
    width: 65.5px;
    right: 10PX;
    top: 5PX;
  }

  .word-container {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: flex-start;
    // margin-left: 180px;
    width: 408px;
    margin: 0 auto;

    &:nth-of-type(1) {
      z-index: 50;
      margin-top: 20px;
      position: relative;
      z-index: 1
    }

    &:nth-of-type(2) {
      margin-top: -36px;
      position: relative;
      z-index: 2;
    }

    .word {
      background: url('~@/assets/index/book-h.png');
      // font-weight: bolder;
      position: relative;
      width: 128px;
      height: 179px;
      color: #fff;
      background-size: 100%;
      background-repeat: no-repeat;

      &+.word {
        margin-left: 12px;
      }

      .icon {
        font-size: 35px;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 124px;
        width: 100%;
        padding: 0 15px;
        box-sizing: border-box;
      }
    }
  }

}</style>
