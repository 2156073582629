<template>
  <!-- 横屏 -->
  <div>
    <horizontalScreen class="v-index">
      <!-- <scaleWrap> -->
      <div class="home">
        <div class="box">
          <div class="word-container">
            <div :id="`word${index}`" :style="{ backgroundImage: 'url(' + word.bg + ')' }" class="word"
              v-for="(word, index) in showWordList.slice(0, 3)" :key="word.albumId">
              <!-- {{word.albumName.slice(1)}} -->
              <div @click.stop.prevent="wordClickHandler($event, word, index)" class="icon"></div>
            </div>
          </div>
          <div class="word-container">
            <div :id="`word${index + 3}`" :style="{ backgroundImage: 'url(' + word.bg + ')' }" class="word"
              v-for="(word, index) in showWordList.slice(3, 6)" :key="word.albumId">
              <!-- {{word.albumName.slice(1)}} -->
              <div @click.stop.prevent="wordClickHandler($event, word, index + 3)" class="icon"></div>
            </div>
          </div>
        </div>
        <img @click="bindHandler" class="btn-bind" src="@/assets/index/btn-bind.png" />
        <img class="logo" src="@/assets/index/img-logo.png" />
        <img class="game-logo" src="@/assets/index/img-ztrz-new.png" />
        <audio id="myAudio" preload></audio>
      </div>
      <!-- </scaleWrap> -->
    </horizontalScreen>
    <bind ref="bind" @on-bind="fetchList" />
  </div>
</template>
<script>
import Bind from '@/components/bind'
import anime from 'animejs/lib/anime.es.js'
import horizontalScreen from '@/components/horizontal-screen'
import { isEmpty } from 'lodash'
import { mapMutations, mapActions, mapGetters } from 'vuex'

export default {
  name: 'Index',
  components: {
    horizontalScreen, Bind
  },
  computed: {
    ...mapGetters(['token', 'albumList']),
    showWordList () {
      return this.albumList.map(item => {
        return {
          ...item,
          bg: item.state == 1 ? require(`@/assets/index/${item.albumId}.png`) : require(`@/assets/index/${item.albumId}-disabled.png`)
        }
      })
    }
  },
  async created () {
    // this.$navigation.cleanRoutes()
    // window.addEventListener('popState', () => {
    //   window.wx.miniProgram.navigateBack()
    // })
    if (this.$route.query.token) {
      console.log('token', decodeURIComponent(this.$route.query.token), decodeURIComponent(this.$route.query.userId))
      this.SET_USER_INFO({ token: decodeURIComponent(this.$route.query.token), userId: decodeURIComponent(this.$route.query.userId) })
    }
    if (this.token) {
      this.$loading.show('数据加载中。。。')
      try {
        if (!this.albumList.length) {
          await this.fetchList()
        }
      } finally {
        this.$loading.hide()

      }
    }
  },
  data () {
    return {
      clickAudio: require(`@/assets/sounds/click.mp3`)
    }
  },
  methods: {
    ...mapMutations(['SET_USER_INFO', 'SET_ALBUM_ID']),
    ...mapActions(['getAlbumList']),
    bindHandler () {
      this.$refs.bind.showBind = true
    },
    async fetchList () {
      await this.getAlbumList()
      // this.showWordList = res.map(item => {
      //   return {
      //     ...item,
      //     bg: item.state == 1 ? require(`@/assets/index/${item.albumId}.png`) : require(`@/assets/index/${item.albumId}-disabled.png`)
      //   }
      // })
    },
    isEmpty,
    playAudio () {
      const myAudio = document.getElementById('myAudio')
      myAudio.src = this.audioList[0]
      myAudio.play()
      let index = 0
      return new Promise((resolve) => {
        myAudio.onended = () => {
          console.log(index, this.audioList)
          if (index < this.audioList.length - 1) {
            myAudio.src = this.audioList[index + 1];
            myAudio.play()
            index++;
          } else {
            this.audioList = []
            resolve()
          }
        };
      })

    },
    // 字点击
    async wordClickHandler (event, word) {
      const parentNode = event.target.parentElement

      anime({
        targets: parentNode,
        scale: [
          { value: 1, duration: 100 },
          { value: 1.1, duration: 100 },
          { value: 1, duration: 100 }
        ],
        complete: () => {
          parentNode.style.transform = 'none'
        }
      });

      this.audioList = []
      this.audioList = [this.clickAudio]
      await this.playAudio()
      if (word.state == 1) {
        // 生效 -1
        this.SET_ALBUM_ID(word.albumId)
        this.$router.push({ name: 'Book' })

      } else {
        this.$refs.bind.showBind = true

      }

    }
  }
}
</script>

<style lang="scss" scoped>
.v-index {
  audio {
    position: absolute;
    top: -1000px;
    left: -1000px;
    z-index: -1;
  }

  .home {
    height: 100%;
    width: 100%;
    background: url(~@/assets/index/bg-index.png) center center;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    overflow: hidden;
    position: relative;
  }

  .box {
    position: absolute;
    bottom: -26px;
    left: 0;
    right: 0;
  }

  // .logo {
  //   width: 12px;
  //   left: 5px;
  //   top: 10PX;
  //   position: fixed;
  // }
  .btn-bind {
    width: 146px;
    height: 52px;
    // width: 109.5px;
    // height: 39px;
    position: fixed;
    bottom: 12px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 99;
  }

  .game-logo {
    width: 73.5px;
    left: 48px;
    top: 5PX;
    position: fixed;
  }

  .word-container {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: flex-start;
    // margin-left: 180px;
    width: 384px;
    margin: 0 auto;

    &:nth-of-type(1) {
      z-index: 50;
      margin-top: 20px;
      position: relative;
      z-index: 1
    }

    &:nth-of-type(2) {
      margin-top: -102px;
      position: relative;
      z-index: 2;
    }

    .word {
      background: url('~@/assets/index/index-xrk.png');
      // font-weight: bolder;
      position: relative;
      width: 120px;
      height: 237.5px;
      color: #fff;
      background-size: 100%;
      background-repeat: no-repeat;

      &+.word {
        margin-left: 12px;
      }

      .icon {
        // font-family: 'Alibaba-PuHuiTi-Bold';
        // font-family: 'FZHuPo-M04S';
        font-size: 35px;
        height: 130px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }

}
</style>
