<template>
  <div class="simple-keyboard"></div>
</template>

<script>
import Keyboard from "simple-keyboard"
import "simple-keyboard/build/css/index.css"

export default {
  name: 'SimpleKeyboard',
  props: {
    input: {
      type: String
    }
  },
  data: () => ({
    keyboard: null
  }),
  mounted() {
    this.keyboard = new Keyboard({
      onChange: input => this.onChange(input),
      onKeyPress: button => this.onKeyPress(button),
      mergeDisplay: true,
      layoutName: 'default',
      layout: {
        default: [
          'q w e r t y u i o p',
          'a s d f g h j k l',
          '{shift} z x c v b n m {backspace}',
          '{numbers} {space} {ent}'
        ],
        shift: [
          'Q W E R T Y U I O P',
          'A S D F G H J K L',
          '{shift} Z X C V B N M {backspace}',
          '{numbers} {space} {ent}'
        ],
        numbers: ['1 2 3', '4 5 6', '7 8 9', '{abc} 0 {backspace}']
      },
      display: {
        '{numbers}': '123',
        '{ent}': 'return',
        '{escape}': 'esc ⎋',
        '{tab}': 'tab ⇥',
        '{backspace}': '⌫',
        '{capslock}': 'caps lock ⇪',
        '{shift}': '⇧',
        '{controlleft}': 'ctrl ⌃',
        '{controlright}': 'ctrl ⌃',
        '{altleft}': 'alt ⌥',
        '{altright}': 'alt ⌥',
        '{metaleft}': 'cmd ⌘',
        '{metaright}': 'cmd ⌘',
        '{abc}': 'ABC'
      }
    })
  },
  methods: {
    onChange(input) {
      this.$emit('onChange', input);
    },
    onKeyPress(button) {
      /**
       * If you want to handle the shift and caps lock buttons
       */
      if (button === "{shift}" || button === "{lock}") this.handleShift();
      if (button === "{numbers}" || button === "{abc}") this.handleNumbers();
      this.$emit('onKeyPress', button)
    },
    handleShift() {
      let currentLayout = this.keyboard.options.layoutName;
      let shiftToggle = currentLayout === "default" ? "shift" : "default";
      console.log('shiftToggle', shiftToggle)
      this.keyboard.setOptions({
        layoutName: shiftToggle
      });
    },
    
    handleNumbers() {
      let currentLayout = this.keyboard.options.layoutName;
      let numbersToggle = currentLayout !== 'numbers' ? 'numbers' : 'default';

      this.keyboard.setOptions({
        layoutName: numbersToggle
      });
    },

    init() {
      if(document.querySelector('#app section').style.transform === 'rotate(0deg)') {
        // 横屏  word-container
        document.querySelector('.simple-keyboard').classList.add('simple-keyboard-horizontal')
      } else {
        const el = document.querySelector('.simple-keyboard')
        el.style.width=document.body.clientHeight + 'px'
        this.$nextTick(() => {
          setTimeout(() => {
            const elHeight = el.offsetHeight
            console.log('elHeight', elHeight)
            el.style.webkitTransform = el.style.transform = `rotate(90deg)`;
            el.style.webkitTransformOrigin = el.style.transformOrigin = `${elHeight/2}px center`;
            el.style.opacity = 1
          }, 0)
        })

      }
    }
  },
  watch: {
    input(input) {
      
      this.keyboard.setInput(input);
    }
  }
};
</script>

<!-- Add 'scoped' attribute to limit CSS to this component only -->
<style lang='scss'>
.simple-keyboard {
  position: fixed;
  z-index: 9999;
  opacity: 0;
  .hg-rows .hg-button {
    font-size: 20px!important;
  }
  &-horizontal {
    opacity: 1;
    position: fixed;
    bottom: 0;
    z-index: 99999;
  }
}
</style>